<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div id="page">
    <Header :add_class="['header-auto-show']" />
    <Footer logged />

    <div
      class="card card-fixed mb-n5"
      data-card-height="420"
      style="height: 420px"
    >
      <div
        class="card rounded-0"
        data-card-height="450"
        style="height: 450px"
        :style="{ 'background-image': 'url(' + image + ')' }"
      >
        <div class="card-bottom px-3 pb-2">
          <h1 class="color-white font-28mb-0">{{ blogEntry.title }}</h1>
          <p class="color-white font-12 opacity-80"></p>
        </div>
        <div class="card-top mt-3 pb-5 ps-3">
          <a
            href="#"
            data-back-button=""
            class="icon icon-s bg-theme rounded-xl float-start me-3"
            @click="$router.go(-1)"
            ><i class="fa color-theme fa-arrow-left"></i
          ></a>
        </div>
        <div class="card-overlay bg-gradient"></div>
      </div>
    </div>
    <div
      class="card card-clear"
      data-card-height="400"
      style="height: 400px"
    ></div>

    <div class="page-content pb-3">
      <div class="card card-full rounded-m pb-4">
        <div class="drag-line"></div>
        <div class="content pb-5">
          <!-- <h1>{{ blogEntry.title }}</h1> -->
          <p class="content-article" v-html="contents.text"></p>
        </div>
      </div>
    </div>
    <!-- Page content ends here-->
    <div
      id="menu-share"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-share.html"
      data-menu-height="250"
    >
      <MenuShare />
    </div>

    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";

import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import MenuShare from "@/components/menu/MenuShare.vue";
export default {
  name: "BlogDetail",
  components: {
    Header,
    Footer,
    MenuShare,
  },
  data() {
    return {
      blogEntry: {},
      contents: {},
      image: "",
      isLoading: true,
    };
  },
  mounted() {
    init_template();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
    var slug = this.$route.params.slug;
    this.getData(slug);
  },
  methods: {
    menuOpen,
    menuClose,
    async getData(slug) {
      await axios
        .get("/api/v1/blog/detail/" + slug)
        .then(({ data }) => {
          this.contents = data.contents.textitem;
          this.blogEntry = data.entry;
          this.image = data.image;
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            console.log("error :" + JSON.stringify(error));
          }
        });
    },
  },
};
</script>

<style scoped>
.content-article > p {
  padding-bottom: 0px !important;
  margin-bottom: 1px !important;
}
</style>
